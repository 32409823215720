// 专业代理人
<template>
  <el-container>
    <el-main>
      <el-card class="box-card">
        <el-form
          :model="agentForm"
          label-width="150px"
          class="form"
          style="margin-top: 50px"
        >
          <el-form-item label="详情" prop="introduction">
            <TEditor8
              ref="editor"
              style="width: 600px; height: 500px"
              :auto-height="true"
              v-model="agentForm.introduction"
              @update="updateData($event)"
              width="100%"
            />
          </el-form-item>
          <el-button class="addbutton" type="primary" @click="addbutton"
            >确定</el-button
          >
        </el-form>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import TEditor8 from "@/components/Tinymce/Tinymce.vue";
export default {
  components: { TEditor8 },
  data() {
    return {
      id: "",
      agentForm: {},
    };
  },
  mounted() {
    this.getTableDataEdit();
  },
  activated() {
    this.getTableDataEdit();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    updateData(e) {
      this.agentForm.introduction = e;
    },
    // 获取接口数据
    addbutton() {
      this.putTableData();
    },
    async getTableDataEdit() {
      await this.$axios.get("/stuffs/GetStuff").then((response) => {
        this.agentForm = { ...response.data };
        this.id = response.data.stuffid;
      });
    },
    //将填报的数据发给后端
    async putTableData() {
      const response = await this.$axios({
        method: "put",
        url: "/stuffs/PutStuff",
        data: {
          stuffid: this.id,
          introduction: this.agentForm.introduction,
        },
        dataType: "json",
      });
      if (response.data.status == "OK") {
        this.$message.success("修改成功");
        this.getTableDataEdit();
      } else {
        this.$message.error("修改失败");
      }
    },
  },
};
</script>

<style scoped>
.el-form .el-form-item /deep/ .el-form-item__label {
  width: 200px !important;
  font-size: 18px;
}
.addbutton {
  margin-top: -10px;
  margin-left: 85%;
}
</style>