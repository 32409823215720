// 法律支持
<template>
  <body>
    <el-card>
      <el-table :data="tableData" style="width: 100%" class="box-table">
        <el-table-column prop="wtime" label="时间" width="100px">
        </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button
              @click="deleteRow(scope.row.lawid)"
              type="text"
              size="middle"
              style="font-size: 15px"
              >删除</el-button
            >
            <el-button
              @click="editRow(scope.$index)"
              type="text"
              size="middle"
              style="font-size: 15px"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button class="addbutton" type="primary" @click="addbutton"
        >添 加</el-button
      >
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 30px; text-align: center"
      >
        <el-button
          style="
            border: 1px solid;
            border-color: #b4bccc;
            padding: 0px;
            margin-left: 6px;
          "
          size="mini"
          @click="handleCurrentChange"
          >GO</el-button
        >
      </el-pagination>
    </el-card>

    <!-- 新建新闻/修改新闻 -->
    <el-dialog title="法律支持" v-model="legalVisible" width="55%">
      <el-form ref="legalScope" :model="legalForm">
        <el-form-item label="标题" prop="title">
          <el-input style="width: 70%" v-model="legalForm.title" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="时间" prop="title">
          <el-date-picker
            v-model="legalForm.wtime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            style="width: 70%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="文件" prop="url">
          <el-upload
            id="uploadImage"
            name="file"
            action="https://imct.oss-cn-beijing.aliyuncs.com"
            :handle-change="handleChange"
            :handle-remove="handleRemove"
            :on-remove="onRemove"
            :file-list="fileList"
            :http-request="toUploadfile"
          >
            <el-button type="primary">选择文件</el-button>
          </el-upload>
          <span class="tiptext" v-show="fileList == ''">未上传文件 </span>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="legalVisible = false"
            >取 消</el-button
          >
          <el-button @click="toSureAddScope" size="small" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </body>
</template>
<script>
import { uploadToAli } from "@/utils/alioss.js";
export default {
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [], //存放table数据
      legalForm: {
        title: "",
        name: "",
        url: "",
        wtime: "",
      },
      legalVisible: false,
      legalId: "",
      fileList: [],
      isVisible: false,
      defaultValue: "",
      date: null,
      time: "",
    };
  },

  mounted() {
    this.getTableData();
  },
  methods: {
    switchPicker() {
      this.isVisible = !this.isVisible;
    },
    setChooseValue(param) {
      console.log("param:" + param);
      const theDate = param[3];
      this.date = theDate;
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    //上传文件
    handleChange(file, fileList) {
      // console.log("这是file", file);
      // console.log("这是fileList", fileList);
      // 当多余一个的时候替换文件
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    //上传文件
    async toUploadfile(file) {
      this.fileList = [];
      var storeAs = "file/" + new Date().getTime() + "&" + file.file.name;
      // this.name = file.file.name
      var uploadUrl = "https://imct.oss-cn-beijing.aliyuncs.com/" + storeAs;
      // this.uploadUrl = uploadUrl
      await uploadToAli(storeAs, file.file);
      const fileObj = {
        name: file.file.name,
        url: uploadUrl,
      };
      this.fileList.push(fileObj);
    },
    handleRemove(file) {
      if (file && file.status === "success") {
        this.$axios.delete("accessory/one/" + file.response.id).then((resp) => {
          if (resp.status == 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
          }
        });
      }
    },
    //删除文件
    onRemove(file, fileList) {
      this.fileList = [];
    },

    async getTableData() {
      await this.$axios
        .get("/laws/GetLawAll", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.tableData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //添加新闻
    addbutton() {
      this.legalId = "";
      (this.fileList = []),
        (this.legalForm = {
          title: "",
          name: "",
          url: "",
          wtime: "",
        });
      this.legalVisible = true;
    },
    //确定添加/修改按钮
    async toSureAddScope() {
      if (this.legalId === "") {
        if (this.legalForm.wtime !== "") {
          this.time = this.legalForm.wtime.setHours(
            this.legalForm.wtime.getHours() + 8
          );
          this.time = new Date(this.time)
            .toLocaleDateString()
            .split("/")
            .join("-");
        }
        const response = await this.$axios({
          method: "post",
          url: "/laws/InputLaw",
          data: {
            title: this.legalForm.title,
            name: this.fileList[0].name,
            url: this.fileList[0].url,
            wtime: this.time,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("添加成功");
          this.getTableData();
          scrollBy(0, -top);
          this.legalVisible = false;
        } else {
          this.$message.error("添加失败");
          // location.reload();
        }
      } else {
        if (typeof this.legalForm.wtime !== "string") {
          this.legalForm.wtime = this.legalForm.wtime.setHours(
            this.legalForm.wtime.getHours() + 8
          );
          this.legalForm.wtime = new Date(this.legalForm.wtime)
            .toLocaleDateString()
            .split("/")
            .join("-");
        }
        const response = await this.$axios({
          method: "put",
          url: "/laws/Putlaw",
          data: {
            lawid: this.legalId,
            title: this.legalForm.title,
            name: this.fileList[0].name,
            url: this.fileList[0].url,
            wtime: this.legalForm.wtime,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("修改成功");
          this.getTableData();
          scrollBy(0, -top);
          this.legalVisible = false;
        } else {
          this.$message.error("修改失败");
          // location.reload();
        }
      }
    },
    //修改服务
    editRow(index) {
      let data = this.tableData[index];
      this.legalId = data.lawid;
      this.fileList = [];
      const fileObj = {
        name: data.name,
        url: data.url,
      };
      this.fileList.push(fileObj);
      this.legalForm = {
        title: data.title,
        name: data.name,
        url: data.url,
        wtime: data.wtime,
      };
      this.legalVisible = true;
    },
    //删除服务
    async deleteRow(id) {
      if (!confirm("确认要删除？")) {
        window.event.returnValue = false;
      } else {
        await this.$axios({
          url: "/laws/DeletLaw",
          method: "delete",
          data: {
            lawid: id,
          },
          dataType: "json",
        }).then((response) => {
          if (response.data.status === "OK") {
            this.getTableData();
            this.$message.success("删除成功");
          } else {
            this.$message.error("删除失败");
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.addbutton {
  margin-top: 20px;
  margin-left: 85%;
}
.tiptext {
  font-size: 10px;
  color: rgb(125, 127, 129);
  margin-left: 50px;
}
</style>