// 公司概况
<template>
  <el-container>
    <el-main>
      <el-card class="box-card">
        <el-form
          :model="compForm"
          label-width="150px"
          class="form"
          style="margin-top: 50px"
        >
          <el-form-item label="详情" prop="content">
            <TEditor2
              ref="editor"
              style="width: 600px; height: 500px"
              :auto-height="true"
              v-model="compForm.content"
              @update="updateData($event)"
              width="100%"
            />
          </el-form-item>
          <el-button class="addbutton" type="primary" @click="addbutton"
            >确定</el-button
          >
        </el-form>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import TEditor2 from "@/components/Tinymce/Tinymce.vue";
export default {
  components: { TEditor2 },
  data() {
    return {
      id: "",
      compForm: {},
    };
  },
  mounted() {
    this.getTableDataEdit();
  },
  activated() {
    this.getTableDataEdit();
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  methods: {
    updateData(e) {
      this.compForm.content = e;
    },
    addbutton() {
      this.putTableData();
    },
    async getTableDataEdit() {
      await this.$axios
        .get("/profiles/GetProfile")
        // 将get到的接口数据赋给tableDataEdits】
        .then((response) => {
          this.compForm = { ...response.data };
          this.id = response.data.profileid;
        });
    },
    //将填报的数据发给后端
    async putTableData() {
      const response = await this.$axios({
        method: "put",
        url: "/profiles/PutProfile",
        data: {
          profileid: this.id,
          content: this.compForm.content,
        },
        dataType: "json",
      });
      if (response.data.status == "OK") {
        this.$message.success("修改成功");
        this.getTableDataEdit();
        // this.id = response.data.data.profileid;
      } else {
        this.$message.error("修改失败");
      }
    },
  },
};
</script>

<style scoped>
.el-form .el-form-item /deep/ .el-form-item__label {
  width: 200px !important;
  font-size: 18px;
}
.addbutton {
  margin-top: -10px;
  margin-left: 85%;
}
</style>