<template>
  <div id="text">
    <h5>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <!-- <font style="color: white"
        ></font
      > -->
    </h5>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
#text {
  display: flex;
  justify-content: left;
  background: rgb(47, 46, 46);
  position: initial;
}
</style>