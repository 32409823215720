
<template>
  <el-menu
    :default-active="activeIndex2"
    class="el-menu-demo"
    mode="horizontal"
    @select="handleSelect"
    background-color="white"
    text-color="#000"
  >
    <el-menu-item index="1" style="font-size: 18px"
      ><strong @click="gotoIndex"
        >沈阳东大知识产权代理有限公司</strong
      ></el-menu-item
    >

    <el-submenu index="6" id="rightId2">
      <template #title>
        <el-image
          :src="require('@/static/user.png')"
          style="height: 25px; width: 25px"
        ></el-image>
        <strong>&nbsp;&nbsp;{{ user }}</strong>
      </template>
      <!-- <el-menu-item index="6-1" @click="getInfo">个人信息 </el-menu-item> -->
      <el-menu-item index="6-2" @click="getLogout">登出</el-menu-item>
    </el-submenu>
    <!-- <el-menu-item index="7"><strong>用户</strong></el-menu-item> -->
  </el-menu>
</template>

<script>
export default {
  name: "demo",
  data() {
    // this.
    return {
      studentID: document.cookie.split(";")[0].split("=")[1],
      activeIndex: "1",
      activeIndex2: "1",
      value: "Main",
      user: localStorage.getItem("name"),
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      this.value = key;
    },
    getLogout() {
      this.$router.push("/");
    },
    gotoIndex() {
      this.$router.push("/layout/homepage");
    },
    // getInfo(){
    //   this.$router.push("/layout/infoDetail");
    // }
  },
};
</script>

<style scpoed>
#icon2 {
  height: 30px;
  width: 30px;
  margin-right: 5px;
  margin-left: -5px;
}
.el-menu-item is-active {
}
.el-menu-demo #rightId1 {
  position: absolute;
  right: 110px;
}
.el-menu-demo #rightId2 {
  position: absolute;
  right: 0px;
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 100px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>

