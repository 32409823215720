<template>
  <div class="editor-container" style="z-index: 1001">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="height: 500px; overflow-y: hidden;"
        v-model="valueHtml"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="handleCreated"
    />
  </div>
</template>

<script>
import '@wangeditor-next/editor/dist/css/style.css' // 引入 css

import {onBeforeUnmount, ref, shallowRef, onMounted, watch} from 'vue'
import { Editor, Toolbar } from '@wangeditor-next/editor-for-vue'
import axios from "axios";

export default {
  components: { Editor, Toolbar },
  props: {
    modelValue: {
      type: String,
      default: '',
    }
  },
  emits: ["update"],
  setup(props, {emit}) {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref(props.modelValue)
    const toolbarConfig = {}
    const editorConfig = {
      MENU_CONF: {},
    };
    editorConfig.placeholder = '请输入专利内容';

    editorConfig.MENU_CONF['uploadImage'] = {
      async customUpload(file, insertFn) {
        const maxSize = 10 * 1024 * 1024; // 1MB
        if (file.size > maxSize) {
          alert('上传的图像文件不能超过 10MB');
          return; // 终止上传
        }
        // file 选中的文件
        const fd = new FormData()
        fd.append('file', file)
        fd.append('type','img')
        const uploadURL = `${axios.defaults.baseURL}files/editorUpload`; // 拼接上传 URL
        axios
            .post(uploadURL, fd)
            .then((response) => {
              const url = response.data.data.url
              // 插入图片
              insertFn(url)
            })
            .catch(() => {})
      }
    };
    editorConfig.MENU_CONF['uploadVideo'] = {
      async customUpload(file, insertFn) {
        const maxSize = 10 * 1024 * 1024; // 1MB
        if (file.size > maxSize) {
          alert('上传的视频文件不能超过 10MB');
          return; // 终止上传
        }
        // file 即选中的文件
        const fd = new FormData()
        fd.append('file', file)
        fd.append('type','video')
        const uploadURL = `${axios.defaults.baseURL}files/editorUpload`; // 拼接上传 URL
        axios
            .post(uploadURL, fd)
            .then((response) => {
              const url = response.data.data.url
              // 插入视频
              insertFn(url)
            })
            .catch(() => {})
      }
    }

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor
    }
    const handleEditorChange = (value) =>{
      valueHtml.value = value;
      emit('update', value);  // 使用 context 中的 emit
    };

    watch(
        () => valueHtml.value, () => { handleEditorChange(valueHtml.value); },
        { deep: true, immediate: true }
    );

    watch(() => props.modelValue, (newValue) => {
      valueHtml.value = newValue; // 更新编辑器内容
      // console.log(newValue)
    },{ deep: true, immediate: true });


    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      handleEditorChange
    };
  }
}
</script>
<style>
.editor-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  width: 80%;
  height: 400px;
}
</style>