<template>
  <el-container>
    <el-main>
      <el-card class="box-card">
        <el-form
          :model="tableData"
          label-width="150px"
          class="form"
          style="margin-top: 50px"
        >
          <el-form-item label="标题" prop="title" class="height55">
            <el-input v-model="tableData.title"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address" class="height55">
            <el-input v-model="tableData.address"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone" class="height55">
            <el-input v-model="tableData.phone"></el-input>
          </el-form-item>
          <el-form-item label="微信" prop="wchat" class="height55">
            <el-input v-model="tableData.wchat"></el-input>
          </el-form-item>
          <el-form-item label="QQ" prop="qq" class="height55">
            <el-input v-model="tableData.qq"></el-input>
          </el-form-item>
          <el-form-item label="邮编" prop="postal" class="height55">
            <el-input v-model="tableData.postal"></el-input>
          </el-form-item>
          <el-form-item label="文件" prop="certificateurl">
            <el-upload
              id="uploadImage"
              action="https://imct.oss-cn-beijing.aliyuncs.com"
              name="file"
              :before-remove="beforeRemove"
              :handle-remove="handleRemove"
              :on-remove="onRemove"
              :handle-change="handleChange"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :http-request="toUploadfile"
            >
              <el-button class="upload-file" size="middle" type="primary"
                ><i
                  style="margin-left: -3px; font-size: 15px"
                  class="el-icon-upload2"
                ></i
                >上传文件</el-button
              >
            </el-upload>
          </el-form-item>

          <el-row style="margin-top: 100px">
            <el-form-item label-width="100px">
              <el-col :span="10">
                <el-button
                  @click="postTableData"
                  style="
                    margin-left: 600px;
                    width: 100px;
                    background-color: #409eff;
                    color: #fff;
                    font-size: 15px;
                  "
                  size="large"
                  type="primary"
                  >确定</el-button
                >
              </el-col>
            </el-form-item>
          </el-row>
        </el-form>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import { uploadToAli } from "@/utils/alioss.js";
export default {
  name: "upload",
  data() {
    this.id = localStorage.getItem("operatorId");
    return {
      tableData: {
        title: "",
        address: "",
        phone: "",
        wchat: "",
        qq: "",
        postal: "",
        maps: "",
        filename: "",
        fileurl: "",
      },
      fileList: [],
    };
  },
  methods: {
    handleChange(file, fileList) {
      // console.log("这是file", file);
      // console.log("这是fileList", fileList);
      // 当多余一个的时候替换文件
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    //上传文件
    async toUploadfile(file) {
      this.fileList = [];
      var storeAs = "file/" + new Date().getTime() + "&" + file.file.name;
      // this.name = file.file.name
      var uploadUrl = "https://imct.oss-cn-beijing.aliyuncs.com/" + storeAs;
      // this.uploadUrl = uploadUrl
      await uploadToAli(storeAs, file.file);
      const fileObj = {
        name: file.file.name,
        url: uploadUrl,
      };
      this.fileList.push(fileObj);
    },
    onRemove(file, fileList1) {
      this.fileList = [];
    },
    async postTableData() {
      const response = await this.$axios({
        method: "post",
        url: "/contacts/InputContact",
        data: {
          title: this.contactForm.title,
          address: this.contactForm.address,
          phone: this.contactForm.phone,
          wchat: this.contactForm.wchat,
          qq: this.contactForm.qq,
          postal: this.contactForm.postal,
          maps: this.contactForm.maps,
          filename: this.fileList[0].name,
          fileurl: this.fileList[0].url,
        },
        dataType: "json",
      });

      if (response.data.status == "OK") {
        this.$message.success("添加成功");
        this.$router.push("/contactWay");
      } else {
        this.$message.error("添加失败");
        location.reload();
      }
    },
  },
  mounted() {},
  activated() {},
};
</script>

<style scoped>
.person /deep/ .el-form-item__label:before {
  content: "* ";
  color: red;
}
.tiptext {
  font-size: 10px;
  color: rgb(125, 127, 129);
}
.el-form .height55 {
  height: 55px;
}
.el-form .person {
  height: 90px;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1050px;
}
.el-form .el-form-item /deep/ .el-form-item__label {
  margin-left: 150px;
  margin-right: -150px;
  width: 200px !important;
  font-size: 18px;
}
.el-form
  .el-form-item
  /deep/
  .el-form-item__content
  .el-input
  .el-input__inner {
  height: 40px;
  font-size: 15px;
}
.radio {
  width: 250px;
}
.updateButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
</style>