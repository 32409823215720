// 新闻动态
<template>
  <body>
    <el-card>
      <el-table :data="tableData" style="width: 100%" class="box-table">
        <el-table-column prop="wtime" label="时间" width="100px">
        </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button
              @click="deleteRow(scope.row.newsid)"
              type="text"
              size="middle"
              style="font-size: 15px"
              >删除</el-button
            >
            <el-button
              @click="editRow(scope.$index)"
              type="text"
              size="middle"
              style="font-size: 15px"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button class="addbutton" type="primary" @click="addbutton"
        >添 加</el-button
      >
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 30px; text-align: center"
      >
        <el-button
          style="
            border: 1px solid;
            border-color: #b4bccc;
            padding: 0px;
            margin-left: 6px;
          "
          size="mini"
          @click="handleCurrentChange"
          >GO</el-button
        >
      </el-pagination>
    </el-card>

    <!-- 新建新闻/修改新闻 -->
    <el-dialog title="新闻动态" v-model="newsVisible" width="55%">
      <el-form ref="newScope" :model="newsForm">
        <el-form-item label="标题" prop="title">
          <el-input style="width: 70%" v-model="newsForm.title" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="时间" prop="title">
          <el-date-picker
            v-model="newsForm.wtime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            style="width: 70%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="详情" prop="content">
          <TEditor4
            ref="editor"
            style="width: 80%; height: 500px"
            :auto-height="true"
            v-model="newsForm.content"
            @update="updateData($event)"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="newsVisible = false">取 消</el-button>
          <el-button @click="toSureAddScope" size="small" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </body>
</template>
<script>
import TEditor4 from "@/components/Tinymce/Tinymce.vue";
export default {
  components: { TEditor4 },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [], //存放table数据
      newsForm: {
        title: "",
        content: "",
        wtime: "",
      },
      newsVisible: false,
      newsId: "",
      time: "",
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    updateData(e) {
      this.newsForm.content = e;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    async getTableData() {
      await this.$axios
        .get("/news/GetNewsAll", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.tableData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //添加新闻
    addbutton() {
      this.newsId = "";
      this.newsForm = {
        title: "",
        content: "",
        wtime: "",
      };
      this.newsVisible = true;
    },
    //确定添加/修改按钮
    async toSureAddScope() {
      if (this.newsId === "") {
        if (this.newsForm.wtime !== "") {
          this.time = this.newsForm.wtime.setHours(
            this.newsForm.wtime.getHours() + 8
          );
          this.time = new Date(this.time)
            .toLocaleDateString()
            .split("/")
            .join("-");
        }
        const response = await this.$axios({
          method: "post",
          url: "/news/InputNews",
          data: {
            title: this.newsForm.title,
            content: this.newsForm.content,
            wtime: this.time,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("添加成功");
          this.getTableData();
          scrollBy(0, -top);
          this.newsVisible = false;
        } else {
          this.$message.error("添加失败");
          // location.reload();
        }
      } else {
        if (typeof this.newsForm.wtime !== "string") {
          this.newsForm.wtime = this.newsForm.wtime.setHours(
            this.newsForm.wtime.getHours() + 8
          );
          this.newsForm.wtime = new Date(this.newsForm.wtime)
            .toLocaleDateString()
            .split("/")
            .join("-");
        }
        const response = await this.$axios({
          method: "put",
          url: "/news/PutNews",
          data: {
            newsid: this.newsId,
            title: this.newsForm.title,
            content: this.newsForm.content,
            wtime: this.newsForm.wtime,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("修改成功");
          this.getTableData();
          scrollBy(0, -top);
          this.newsVisible = false;
        } else {
          this.$message.error("修改失败");
          // location.reload();
        }
      }
    },
    //修改服务
    editRow(index) {
      let data = this.tableData[index];
      this.newsId = data.newsid;
      this.newsForm = {
        title: data.title,
        content: data.content,
        wtime: data.wtime,
      };
      this.newsVisible = true;
    },
    //删除服务
    async deleteRow(id) {
      if (!confirm("确认要删除？")) {
        window.event.returnValue = false;
      } else {
        await this.$axios({
          url: "/news/DeletNews",
          method: "delete",
          data: {
            newsid: id,
          },
          dataType: "json",
        }).then((response) => {
          if (response.data.status === "OK") {
            this.getTableData();
            this.$message.success("删除成功");
          } else {
            this.$message.error("删除失败");
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.addbutton {
  margin-top: 20px;
  margin-left: 85%;
}
</style>