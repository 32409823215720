// 专利代理人
<template>
  <body>
    <div class="homeback">
      <div class="back">
        <img :src="imgSrc" alt="" />
      </div>
      <div class="back1">
        <img :src="imgSrc1" alt="" />
      </div>
      <div class="hometitle">
        <h3>Hi~欢迎登入</h3>
        <h1>专利代理人</h1>
      </div>
    </div>
  </body>
</template>
<script>
export default {
  data() {
    return {
      imgSrc: require("@/static/homeback.jpg"),
      imgSrc1: require("@/static/homeback1.jpg"),
    };
  },
};
</script>

<style scoped>
.homeback {
  display: flex;
  background-color: #f8f8f9;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hometitle {
  z-index: 0;
  float: left;
  margin: auto;
  margin-left: 80px;
  margin-bottom: -450px;
}
.back img {
  width: 100%;
  height: 600px;
  position: absolute;
}
.back1 img {
  width: 100%;
  height: 600px;
  margin-top: 600px;
  position: absolute;
}
</style>