'use strict'

let OSS = require('ali-oss')

let client = new OSS({
        region: 'oss-cn-beijing.aliyuncs.com',
        secure: true,
        endpoint: 'oss-cn-beijing.aliyuncs.com',
        accessKeyId: "LTAIoFYLvVO3RJrM",
        accessKeySecret: "GTpOlKUSuOMTUwKn5gTy91BMMLvJFM",
        bucket: 'imct',
        
})   //阿里云提供的数据
export function uploadToAli(storeAs,file) {
    try {
        let result = client.put(storeAs, file)
        
      } catch (err) {
       
      }
}