<template>
  <div class="total">
    <div id="div1"><Header /></div>
    <div id="div2">
      <div id="left">
        <Navbar />
      </div>
      <div id="right">
        <Main />
      </div>
    </div>
    <div id="div3"><Footer /></div>
  </div>
  <!-- <Header />
    <Main>
      <router-view />
    </Main>
    <Navbar />
  </div> -->
</template>

<script>
// 会导入./Header下面的index.vue组件
import Header from "./Header";
import Navbar from "./Navbar";
import Main from "./Main";
import Footer from "./Footer";

export default {
  name: "Layout",
  components: { Header, Navbar, Main, Footer },
};
// 导入子组件，缩写格式 AppHeader: AppHeader
</script>

<style scoped>
.total {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
#div1 {
  /* height: 80px; */
  height: 10%;
  width: 100%;
  flex: 0;
}
#div2 {
  /* flex: 1; */
  /* overflow: scroll; */
  display: flex;
  flex-direction: row;
  height: 80%;
}
#div3 {
  /* height: 80px; */
  height: 10%;
  width: 100%;
  flex: 0;
  margin-top: 5%;

  /* margin-top: 500px; */
}
#left {
  /* background-color: red; */
  /* height: 100px; */
  /* height: 100%; */
  overflow: hidden;
}
#right {
  flex: 1;
  background-color: #f8f8f9;
  /* height: 1000px; */
  height: 100%;
  /* background-color: blue; */
  /* height: 100px; */
}
</style>