// 服务领域
<template>
  <body>
    <el-card>
      <el-table :data="tableData" style="width: 100%" class="box-table">
        <el-table-column label="服务">
          <template #default="scope">
            <div @click="gotoChild(scope.$index)" style="cursor: pointer">
              {{ scope.row.title }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button
              @click="deleteRow(scope.row.serviceid)"
              type="text"
              size="middle"
              style="font-size: 15px"
              >删除</el-button
            >
            <el-button
              @click="editRow(scope.$index)"
              type="text"
              size="middle"
              style="font-size: 15px"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button class="addbutton" type="primary" @click="addbutton"
        >添 加</el-button
      >
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 30px; text-align: center"
      >
        <el-button
          style="
            border: 1px solid;
            border-color: #b4bccc;
            padding: 0px;
            margin-left: 6px;
          "
          size="mini"
          @click="handleCurrentChange"
          >GO</el-button
        >
      </el-pagination>
    </el-card>
    <!-- 新建服务/修改服务 -->
    <el-dialog title="服务领域" v-model="newScopeVisible" width="55%">
      <el-form ref="newScope" :model="newScopeForm">
        <el-form-item label="标题" prop="title">
          <el-input style="width: 70%" v-model="newScopeForm.title" clearable>
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="newScopeVisible = false"
            >取 消</el-button
          >
          <el-button @click="toSureAddScope" size="small" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </body>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [], //存放table数据
      newScopeForm: {
        title: "",
      },
      newScopeVisible: false,
      newScopeid: "",
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    async getTableData() {
      await this.$axios
        .get("/services/GetServiceAll", {
          params: {
            pagesize: this.pageSize,
            currentpage: this.currentPage,
          },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            this.tableData = response.data.data;
            this.total = response.data.total;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //添加服务
    addbutton() {
      this.newScopeid = "";
      this.newScopeForm = {
        title: "",
      };
      this.newScopeVisible = true;
    },
    //修改服务
    editRow(index) {
      let data = this.tableData[index];
      this.newScopeid = data.serviceid;
      this.newScopeForm = {
        title: data.title,
      };
      this.newScopeVisible = true;
    },
    //确定添加/修改按钮
    async toSureAddScope() {
      if (this.newScopeid === "") {
        const response = await this.$axios({
          method: "post",
          url: "/services/InputService",
          data: {
            title: this.newScopeForm.title,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("添加成功");
          this.getTableData();
          scrollBy(0, -top);
          this.newScopeVisible = false;
        } else {
          this.$message.error("添加失败");
          // location.reload();
        }
      } else {
        const response = await this.$axios({
          method: "put",
          url: "/services/PutService",
          data: {
            serviceid: this.newScopeid,
            title: this.newScopeForm.title,
          },
          dataType: "json",
        });
        if (response.data.status === "OK") {
          this.$message.success("修改成功");
          this.getTableData();
          scrollBy(0, -top);
          this.newScopeVisible = false;
        } else {
          this.$message.error("修改失败");
          // location.reload();
        }
      }
    },
    //修改服务
    gotoChild(index) {
      this.$router.push({
        name: "servscopeChild",
        params: this.tableData[index],
      });
    },
    //删除服务
    async deleteRow(id) {
      if (!confirm("确认要删除？")) {
        window.event.returnValue = false;
      } else {
        await this.$axios({
          url: "/services/DeletService",
          method: "delete",
          data: {
            serviceid: id,
          },
          dataType: "json",
        }).then((response) => {
          if (response.data.status === "OK") {
            this.getTableData();
            this.$message.success("删除成功");
          } else {
            this.$message.error("删除失败");
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.addbutton {
  margin-top: 20px;
  margin-left: 85%;
}
</style>